import { css } from 'styled-components';
import iconDownload from '../images/icons/download.svg';
import longArrow from '../images/icons/long-arrow.svg';
import listArrow from '../images/icons/list-arrow.svg';
import arrowLeft from '../images/icons/arrow-left.svg';
import arrowRight from '../images/icons/arrow-right.svg';
import loader from '../images/icons/loader.svg';
import trash from '../images/icons/trash.svg';

/**
 * Generelle Mixins
 * ----------------------------------------------------------------------------
 */

/**
 * Mixin um Pixel zu REM zu konvertieren
 * @param  {Array} values Beliebige Anzahl von Zahlen
 * @return {String}
 * @example
 *     padding: ${remCalc(5, 20, 0)}; ===> padding: 0.3125rem 1.25rem 0;
 */
export const remCalc = (...values) =>
    values
        .map(value => {
            const numVal = parseInt(value, 10);
            return numVal === 0 ? '0' : `${numVal / 16}rem`;
        })
        .join(' ');

/**
 * Mixin um einfach eine absolute/fixed Position anzugeben
 * @param  {Object} _ mit folgenden Keys:
 *     @param  {String} top
 *     @param  {String} right
 *     @param  {String} bottom
 *     @param  {String} left
 * @param  {String} [pos='absolute'] fixed/sticky/relative
 * @example
 *      const Text = styled.p`
 *          ${position({ top: '50%', left: '50%' })}
 *      `;
 *
 *      Ergebnis:
 *      position: absolute;
 *      top: 50%;
 *      left: 50%;
 */
export const position = (coords = {}, pos = 'absolute') => css`
    position: ${pos};
    ${coords && coords.top ? `top: ${coords.top};` : null}
    ${coords && coords.right ? `right: ${coords.right};` : null}
    ${coords && coords.bottom ? `bottom: ${coords.bottom};` : null}
    ${coords && coords.left ? `left: ${coords.left};` : null}
`;

/**
 * Helper um Code in Pseudo-Klassen für Hover etc. zu wrappen
 * @param  {String} args Zu wrappendes CSS
 * @return {String}      Gewrapptes CSS
 */
export const pseudoStateClasses = (...args) => css`
    &:hover,
    &:focus,
    &:active {
        ${css(...args)};
    }
`;

/**
 * Erweitert Elemente um die Möglichkeit, den Box-Shadow über globale
 * Werte zu steuern.
 *
 * @param {number} defaultLayer Optional: Die Standard-Ebene/Größe des Schattens
 *
 * @example ${layerable(1)};
 */
export const layerable = (defaultLayer = 0) => ({ layer }) =>
    (l => `box-shadow: ${l}px ${l}px ${l * 10}px rgba(0,0,0,0.2)`)(layer || defaultLayer);

/**
 * Media-Queries
 * ----------------------------------------------------------------------------
 */
/**
 * Breakpoints
 * @type {Object}
 */
export const breakpoints = {
    xsmall: remCalc(0),
    small: remCalc(450),
    medium: remCalc(640),
    mediumDown: remCalc(639),
    large: remCalc(1024),
    largeDown: remCalc(1023),
    xlarge: remCalc(1200),
    xlargeDown: remCalc(1199),
    xxlarge: remCalc(1440),
    xxlargeDown: remCalc(1439),
    xxxlarge: remCalc(1680),
    xxxlargeDown: remCalc(1679),
};

/**
 * Mixin, um wiederverwertbare MediaQueries zu erhalten
 * @param  {Object} mq Beliebige Anzahl von Zahlen
 * @return {String} In MQ gewrapptes CSS
 * @example
 *     font-size: 16px;
 *     ${mq.medium`font-size: 20px`};
 */
export const mq = {
    smallOnly: (...args) => css`
        @media (max-width: ${breakpoints.mediumDown}) {
            ${css(...args)};
        }
    `,
    medium: (...args) => css`
        @media (min-width: ${breakpoints.medium}) {
            ${css(...args)};
        }
    `,
    mediumDown: (...args) => css`
        @media (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    mediumOnly: (...args) => css`
        @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    large: (...args) => css`
        @media (min-width: ${breakpoints.large}) {
            ${css(...args)};
        }
    `,
    largeDown: (...args) => css`
        @media (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    largeOnly: (...args) => css`
        @media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    xlarge: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) {
            ${css(...args)};
        }
    `,
    xlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) and (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) {
            ${css(...args)};
        }
    `,
    xxlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) and (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxxlarge}) {
            ${css(...args)};
        }
    `,
};

/**
 * Schriften
 * ----------------------------------------------------------------------------
 */
/**
 * Schriftart
 * @type {Object}
 */
export const fontFamily = 'ITC Franklin Gothic LT, Arial, serif';

/**
 * Schriftgrößen
 * @type {Object}
 */
export const fontSizes = {
    mini: 12,
    standard: 18,
    h1: 35,
    h2: 22,
    h3: 20,
    h4: 18,
};

/**
 * Helper für Responsive Font-Sizes.
 *
 * @param {string} size Die gewünschte Schriftgröße ('mini', 'standard', 'h1', 'h2', 'h3')
 *
 * @example
 *      .diesdas {
 *          background-color: red;
 *          ${fs('h2')}
 *      }
 */
export const fs = size => css`
    font-size: ${remCalc(fontSizes[size])};
    ${mq.medium`font-size: ${remCalc(fontSizes[size] * 2 - fontSizes[size] / 2 - 8)}`};
    ${mq.large`font-size: ${remCalc(fontSizes[size] * 2 - fontSizes[size] / 2 - 6)}`};
`;

/**
 * Erweitert Elemente um die Möglichkeit, die Text-Ausrichtung zu steuern.
 *
 * @param {number} defaultAlign Optional: Die Standard-Ausrichtung
 *
 * @example ${textAlignable('center')};
 */
export const textAlignable = (defaultAlign = 'inherit') => ({ textAlign }) =>
    `text-align: ${textAlign || defaultAlign}`;

/**
 * Anstände
 * ----------------------------------------------------------------------------
 */
/**
 * Abstände
 * @type {Object}
 */
export const gaps = {
    none: 0,
    s: remCalc(4),
    m: remCalc(8),
    l: remCalc(16),
    xl: remCalc(24),
    xxl: remCalc(32),
    xxxl: remCalc(48),
    xxxxl: remCalc(60),
};

/**
 * Erweitert Elemente um die Möglichkeit, innerhalb eines Flex-Grids
 * ihre Ausbreitung zu bestimmen.
 * Im Element ... gridColStart="1" gridColEnd="3"
 */
export const gridPosable = () => ({ gridColStart, gridColEnd }) => {
    if (gridColStart) {
        return `
            grid-column-start: ${Number(gridColStart)};
            grid-column-end: ${gridColEnd ? Number(gridColEnd) + 1 : -1};
            width: 100%;
        `;
    }
    return false;
};

/**
 * Erweitert Elemente um die Möglichkeit, die Abstände nach unten
 * über global einheitliche Werte zu steuern. Auch mit viewportspezifischen
 * Einstellungen möglich
 *
 * @param {string} defaultGap Optional: der Standard-Abstand
 *
 * @example ${gapable('l')};
 * @example ${gapable({small: 'xl', medium: 'l', ...}};
 */
export const gapable = (defaultGap = 'none') => ({ gap }) => {
    if ((gap && typeof gap === 'object') || (typeof defaultGap === 'object' && !gap)) {
        const gapObject = gap || defaultGap;
        let mqedGaps = '';
        Object.keys(gapObject).forEach(key => {
            const mqFunction = mq[key];

            if (key === 'small') {
                mqedGaps = css`
                    margin-bottom: ${gaps[gapObject[key]]};
                `;
            } else if (mqFunction && gapObject[key]) {
                const query = css`
                    ${mqFunction`margin-bottom: ${gaps[gapObject[key]]}`};
                `;
                mqedGaps = css`${mqedGaps}${query}`;
            }
        });
        return mqedGaps;
    }

    // Gaps ohne mq
    return `margin-bottom: ${gap in gaps ? gaps[gap] : gaps[defaultGap]}`;
};

/**
 * Sonstige Variablen
 * ----------------------------------------------------------------------------
 */
/**
 * Breiten der Container
 * @type {Object}
 */
export const containers = {
    fullWidth: '100%',
    s: remCalc(745),
    m: remCalc(970),
    l: remCalc(1170),
};

/**
 * Erweitert Elemente um die Möglichkeit, die Größe über global
 * einheitliche Werte zu steuern.
 *
 * @param {string} defaultSize Optional: die Standard-Größe
 *
 * @example ${sizable('l')};
 */
export const sizable = (defaultSize = 'auto') => ({ size }) =>
    `max-width: ${containers[size] ? containers[size] : defaultSize}`;

/**
 * Farben
 * @type {Object}
 */
export const colors = {
    primary: '#2A6AB7',
    blue: '#2A6AB7',
    lightBlue: '#dde4f6',
    blueMedium: '#4d8cc9',

    red: '#d22321',
    yellow: '#ffdd00',

    transparent: 'transparent',
    black: '#000000',
    white: '#ffffff',
    gray: '#dfdfdf',
    grayLight: '#f1f1f1',
    grayMedium: '#b3b3b3',
    grayDarker: '#444444',
    grayDark: '#1a1a1a',
    grayForms: '#d6d6d6',

    // state colors
    success: '#7aba4e',
    warning: '#ffae00',
    alert: '#cc4b37',
};

/**
 * Erweitert Elemente um die Möglichkeit, die Hintergrundfarbe anhand der definierten `colors` zu steuern.
 *
 * @param {number} defaultColor Optional: Die Standard-Farbe
 *
 * @example ${bgColorable('primary')};
 */
export const bgColorable = (defaultColor = 'transparent') => ({ bgColor }) =>
    `background-color: ${colors[bgColor] ? colors[bgColor] : colors[defaultColor]}`;

/**
 * Erweitert Elemente um die Möglichkeit, die Schriftfarbe anhand der definierten `colors` zu steuern.
 *
 * @param {number} defaultColor Optional: Die Standard-Farbe
 *
 * @example ${textColorable('primary')};
 */
export const textColorable = (defaultColor = 'black') => ({ textColor }) =>
    `color: ${colors[textColor] ? colors[textColor] : colors[defaultColor]}`;

/**
 * Hover Transition Speed
 * @type {String}
 */
export const hoverSpeed = '0.25s';

/**
 * Icons
 */
export const icons = {
    longArrow: `url(${longArrow})`,
    listArrow: `url(${listArrow})`,
    download: `url(${iconDownload})`,
    arrowLeft: `url(${arrowLeft})`,
    arrowRight: `url(${arrowRight})`,
    loader: `url(${loader})`,
    trash: `url(${trash})`,
};
