import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import { colors, remCalc, mq, gapable } from '../../helpers/stylehelpers';

import pattern from '../../images/pattern.svg';
import swoosh from '../../images/swoosh.png';

/**
 * Stage Komponente (Layout)
 *
 * @param {node} props.children Der Inhalt der Stage
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 *
 * @example
 *     <Stage>
 *         <StageIntro headline="..." copy="Lorem ipsum dolor sit amet" />
 *     </Stage>
 */
const Stage = styled(({ children, className }) => (
    <div className={`stage ${className}`}>{children}</div>
))`
    align-items: center;
    display: flex;
    background-color: ${rgba('#2281c4', 1)};
    background-image: url(${pattern}), url(${swoosh});
    background-repeat: repeat, no-repeat;
    background-size: 350px 350px, cover;
    background-position: left top, right bottom;
    border-bottom-left-radius: 80% 30%;
    border-bottom-right-radius: 80% 30%;
    justify-content: center;
    box-shadow: 0 4px 4px 0px ${rgba(colors.black, 0.25)},
        inset 0 4px 4px 0px ${rgba(colors.black, 0.15)};
    left: -5%;
    margin-top: ${remCalc(-42)};
    margin-bottom: ${remCalc(25)};
    position: relative;
    padding: 0 5%;
    z-index: 0;
    width: 110%;

    ${gapable('l')};

    ${({ columnOnMobile }) => columnOnMobile && `flex-direction: column;`};
    ${mq.medium`
        border-bottom-left-radius: 80% 80%;
        border-bottom-right-radius: 80% 80%;
        margin-top: ${remCalc(-62)};
        margin-bottom: ${remCalc(25)};
    `};

    ${mq.large`
        flex-direction: row;
    `};

    ${mq.xlarge`
        border-bottom-left-radius: 80% 100%;
        border-bottom-right-radius: 80% 100%;
        background-size: 450px 450px, cover;
        margin-bottom: ${remCalc(40)};
    `};

    ${mq.xxlarge`
        background-size: auto, cover;
        margin-top: ${remCalc(-86)};
    `};
`;

Stage.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    columnOnMobile: PropTypes.bool,
};

Stage.defaultProps = {
    className: null,
    columnOnMobile: false,
};

export default Stage;
