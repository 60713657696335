import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { rgba } from 'polished';
import {
    remCalc,
    mq,
    position,
    pseudoStateClasses,
    colors,
    hoverSpeed,
} from '../../helpers/stylehelpers';
import { shake } from '../../helpers/animations';
import { phased } from '../../helpers/phases';

import primaryLogo from '../../images/logos/sjfp.png';
import secondaryLogo from '../../images/logos/lotto-bw-circle.svg';
import hamburgerOpen from '../../images/icons/hamburger-open.svg';
import hamburgerClose from '../../images/icons/hamburger-close.svg';

/**
 * Wrapper um den Header
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${remCalc(0, 15)};
    position: relative;
    z-index: 1;

    ${mq.medium`
        justify-content: space-between;
    `};

    ${mq.large`
        padding: ${remCalc(0, 30)};
    `};

    ${mq.xxlarge`
        padding: ${remCalc(0, 45)};
    `};

    ${mq.xxxlarge`
        padding: ${remCalc(0, 60)};
    `};

    /* Backdrop */
    &:before {
        ${mq.mediumDown`
            ${position({ top: '0', left: '0' }, 'fixed')};
            background: ${rgba(colors.black, 0.3)};
            content: '';
            display: none;
            width: 100%;
            width: 100vw;
            height: 100%;
            height: 100vh;
            z-index: 1;

            ${({ offCanvasActive }) =>
                offCanvasActive &&
                `
                display: block;
            `};
        `};
    }
`;

/**
 * Off-Canvas-Button
 * @type {StyledComponent}
 */
const OffCanvasToggler = styled.button`
    display: none;
    margin-top: ${remCalc(21)};

    ${mq.medium`
        margin-top: ${remCalc(39)};
    `};

    ${mq.mediumDown`
        align-self: flex-start;
        margin-left: auto;
        background: transparent url(${hamburgerOpen}) no-repeat;
        background-position: center center;
        position: relative;
        box-shadow: none;
        border: none;
        color: red;
        display: block;
        height: ${remCalc(24)};
        cursor: pointer;
        width: ${remCalc(35)};
        z-index: 3;

        ${({ switchIcon }) =>
            switchIcon &&
            `
            background-image: url(${hamburgerClose});
        `};
    `};
`;

/**
 * Navigation
 * @type {StyledComponent}
 */
const Nav = styled.nav`
    ${mq.mediumDown`
        ${position({ top: '0', right: '0' }, 'fixed')};
        padding: ${remCalc(70, 10, 20)};
        background-color: ${colors.white};
        height: 100%;
        height: 100vh;
        overflow-y: auto;
        max-width: ${remCalc(400)};
        transform: translateX(100%);
        transition: box-shadow 0.5s, transform 0.5s;
        width: 80%;
        -webkit-overflow-scrolling: touch;
        z-index: 2;

        ${({ offCanvasActive }) =>
            offCanvasActive &&
            `
            box-shadow: 0 0 3px 3px ${rgba(colors.black, 0.25)};
            transform: translateX(0);
        `};

    `};

    /* Navigation horizontal */
    ${mq.large`
        margin-top: ${remCalc(-12)};
        display: flex;
    `};

    ${mq.xlarge`
        margin-top: ${remCalc(-24)};
    `};

    ${mq.xxlarge`
        margin-top: ${remCalc(-2)};
    `};

    ${mq.xxxlarge`
        margin-top: ${remCalc(-8)};
    `};
`;

/**
 * Aktivklasse für GatsbyLInk
 * @type {String}
 */
const activeClassName = 'navlink--active';

/**
 * Nav-Link
 * @type {StyledComponent}
 */
const NavLink = styled(Link).attrs({
    activeClassName,
})`
    color: ${colors.black};
    display: block;
    font-weight: bold;
    font-size: ${remCalc(18)};
    text-decoration: none;
    transition: color ${hoverSpeed};

    ${mq.mediumDown`
        padding: ${remCalc(15, 10)};
        border-bottom: 1px solid ${colors.gray};

        &:last-child {
            border-bottom: none;
        }
    `};

    ${mq.large`
        padding: ${remCalc(15)} 1.35vw;
        font-size: ${remCalc(20)};
    `};

    ${mq.xlarge`
        padding: ${remCalc(18, 20)};
        font-size: ${remCalc(24)};
    `};

    ${mq.xxxlarge`
        padding: ${remCalc(20, 30)};
        font-size: ${remCalc(25)};
    `};

    /* Active-Styles */
    &.${activeClassName} {
        color: ${colors.red};
        ${mq.large`
            position: relative;
        `};

        &:after {
            ${mq.large`
                ${position({ bottom: '0', left: '50%' })}
                background-color: ${colors.red};
                content: '';
                display: block;
                height: ${remCalc(3)};
                transform: translateX(-50%);
                width: calc(100% - 2.7vw);
            `};

            ${mq.xlarge`
                height: ${remCalc(4)};
                width: calc(100% - 40px);
            `};

            ${mq.xxxlarge`
                width: calc(100% - 60px);
            `};
        }
    }

    /* Hover */
    ${pseudoStateClasses`
        color: ${colors.red};
    `};
`;

/**
 * Shared Styles für die Logos
 * @type {String}
 */
const logoStyles = `
    flex: 0 0 auto;
    > img {
        max-width: 100%;
        width: auto;
    }
`;

/**
 * Logo SJFP
 * @type {StyledComponent}
 */
const PrimaryLogo = styled(Link)`
    ${logoStyles};
    display: block;
    max-width: ${remCalc(130)};

    ${mq.medium`
        max-width: ${remCalc(200)};
    `};

    ${mq.xxlarge`
        max-width: ${remCalc(285)};
    `};
`;
/**
 * Logo Lotto
 * @type {StyledComponent}
 */
const SecondaryLogo = styled.a`
    ${logoStyles};
    display: none;

    ${mq.large`
        display: block;
        max-width: ${remCalc(150)};
    `};

    ${mq.xxlarge`
        align-self: flex-end;
        max-width: ${remCalc(210)};
    `};

    ${pseudoStateClasses`
        animation: ${shake} 0.15s 2 linear;
    `};
`;

/**
 * Header Komponente
 * @class Header
 */
class Header extends React.Component {
    state = {
        offCanvasActive: false,
    };

    handleOffCanvas = () => {
        let { offCanvasActive } = this.state;
        offCanvasActive = !offCanvasActive;
        this.setState({
            offCanvasActive,
        });
    };

    /**
     * Render
     */
    render() {
        const { offCanvasActive } = this.state;
        return (
            <Wrapper offCanvasActive={offCanvasActive}>
                <PrimaryLogo to="/">
                    <img src={primaryLogo} alt="Logo des Sportjugendförderpreises" />
                </PrimaryLogo>
                <OffCanvasToggler onClick={this.handleOffCanvas} switchIcon={offCanvasActive} />
                <Nav offCanvasActive={offCanvasActive}>
                    {phased([<NavLink to="/online-bewerbung">Online-Bewerbung</NavLink>])}
                    <NavLink to="/preise">Preise</NavLink>
                    <NavLink to="/ueber-den-sjfp">Über den SJFP</NavLink>
                    <NavLink to="/jury">Jury</NavLink>
                    <NavLink to="/rueckblick">Rückblick</NavLink>
                    <NavLink to="/downloads">Downloads</NavLink>
                </Nav>
                <SecondaryLogo
                    href="https://www.lotto-bw.de"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={secondaryLogo} alt="Logo von Lotto Baden-Württemberg" />
                </SecondaryLogo>
            </Wrapper>
        );
    }
}

export default Header;
