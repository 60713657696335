/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import {
    bgColorable,
    colors,
    gapable,
    hoverSpeed,
    icons,
    layerable,
    mq,
    pseudoStateClasses,
    remCalc,
    textAlignable,
} from '../../helpers/stylehelpers';
import window from '../../helpers/window';

/**
 * Prop-Types für Links, Buttons, ButtonLinks
 */
const propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    display: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    layer: PropTypes.number,
};

/**
 * Default Props für Links, Buttons, ButtonLinks
 */
const defaultProps = {
    children: null,
    className: null,
    display: null,
    gap: null,
    layer: null,
};

/**
 * Link
 * ----------------------------------------------------------------------------
 * Als Standard Link wird ein Gatsby Link verwendet.
 *
 * Ist im to-Parameter ein mailto:-Wert enthalten, so wird automatisiert eine
 * Spam-Protection aktiviert. Hierfür sollte idealerweise das @ der E-Mail
 * Adresse mit einem "(at)" ersetzt und die ganze Adresse base64-kodiert werden.
 * E-Mail Adressen können mit Browser-Dev-Tools und dem Befehl `btoa('foo@bar.de')` base64 kodiert werden
 *
 * @param {string} props.bgColor Optional: Die Hintergrundfarbe des Links (aus den definierten `colors`)
 * @param {string} props.children Der Inhalt des Links
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.display Optional: Darstellung (block/inline/...)
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {string} props.type Optional: Der Typ des Links (Icon) / "plain"
 * @param {string} props.to Das Ziel des Links
 *
 * @example
 *      <Link to="https://www.google.de/" gap="l">Mehr erfahren</Link>
 *      <Link to="mailto:bWF4Lm11c3RlcihhdClleGFtcGxlLmRl" />  // (max.muster(at)example.de)
 */
export const Link = styled(({ className, type, to, children, target, rel, ...props }) => {
    const match = to.match(/(^http|^mailto:|.*\..{3,4}$)/i);

    // In-Page Links
    if (!match && !target) {
        return (
            <GatsbyLink className={`link ${className}`} to={to} {...props}>
                {children}
            </GatsbyLink>
        );
    }

    // E-Mail Links
    if (match && match[0] === 'mailto:') {
        let encoded = to;
        if (!to.match(/(@|\(at\))/i)) {
            encoded = `mailto:${window.atob(to.replace('mailto:', ''))}`;
        }
        const linkTarget = encoded.replace('(at)', '@');
        const email = linkTarget.replace('mailto:', '').split('@');
        const domain = email[1].split('.');
        return (
            <button
                type="button"
                className={`link ${className}`}
                onClick={() => {
                    // https://stackoverflow.com/a/17723379/1192316
                    window.location.href = linkTarget;
                }}
            >
                {email[0]}
                <span className="js-no-spam" style={{ display: 'none' }}>
                    [spam-protect]
                </span>
                &#64;
                {domain[0]}
                <span className="js-no-spam" style={{ display: 'none' }}>
                    [spam-protect]
                </span>
                &#46;
                {domain[1]}
            </button>
        );
    }

    // Externe Links + Downloads
    return (
        <a className={`link ${className}`} href={to} target={target} rel={rel}>
            {children}
        </a>
    );
})`
    color: ${colors.primary};
    cursor: pointer;
    display: ${({ display }) => display || 'inline-block'};
    font-weight: bold;
    line-height: 1.35em;

    ${bgColorable()};
    ${layerable()};
    ${gapable()};

    ${({ type }) => (type === 'plain' ? 'color: inherit; font-weight: inherit;' : '')};

    button& {
        border: none;
        padding: 0;
    }

    &::before {
        content: ${({ type }) => icons[type] || ''};
        display: inline-block;
        margin-right: 0.3em;
    }
`;

Link.propTypes = {
    ...propTypes,
    to: PropTypes.string.isRequired,
    type: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
};

Link.defaultProps = {
    ...defaultProps,
    type: null,
    target: null,
    rel: null,
};

/**
 * Button
 *
 * @param {string} props.textalign Optional: Ausrichtung des Textes
 *
 * Außerdem werden vom Link folgende Props vererbt:
 * @param {string} props.bgColor Optional: Die Hintergrundfarbe des Links (aus den definierten `colors`)
 * @param {string} props.children Der Inhalt des Links
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.display Optional: Darstellung (block/inline/...)
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {string} props.type Optional: Der Typ des Links (Icon) / "plain"
 * @param {string} props.to Das Ziel des Links
 *
 * @example <Button color={colors.white} to={link.to}>Hier klicken</Button>
 */
export const Button = styled.button`
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    ${textAlignable()};

    ${mq.smallOnly`
        max-width: ${remCalc(320)};
        margin: 0 auto;
    `};
    ${mq.medium`width: auto;`};
    ${mq.xlarge`font-size: ${remCalc(22)}`};
    ${mq.xxlarge`font-size: ${remCalc(24)}`};

    ${({ color }) =>
        color
            ? `border: 2px solid ${color}; color: ${color};`
            : `border: 2px solid ${colors.primary}; color: ${colors.primary};`};

    cursor: pointer;
    display: inline-block;
    padding: 0.35em 0.85em 0.35em 0.85em;
    text-decoration: none;
    transition: color ${hoverSpeed}, border-color ${hoverSpeed}, background-color ${hoverSpeed};

    + button {
        margin: 1em 0 0 0;
        ${mq.medium`
            margin: 0 0 0 1em;
        `};
    }

    &:disabled,
    &:disabled:hover {
        color: ${colors.grayMedium};
        border-color: ${colors.grayMedium};
        pointer-events: none;
    }

    ${pseudoStateClasses`
        ${({ color }) =>
            color
                ? `
                color: ${color === colors.white ? colors.primary : colors.white};
                border-color: ${color};
                background-color: ${color};
            `
                : `
                color: ${colors.white};
                border-color: ${colors.primary};
                background-color: ${colors.primary};
        `};
    `};
`;

Button.propTypes = {
    ...propTypes,
    textalign: PropTypes.string,
};

Button.defaultProps = {
    ...defaultProps,
    textalign: null,
};

export const ButtonLink = Button.withComponent(Link);

ButtonLink.propTypes = {
    ...Link.propTypes,
    ...Button.propTypes,
};

ButtonLink.defaultProps = {
    ...Link.defaultProps,
    ...Button.defaultProps,
};
