import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Location } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';

import TeaserBox from '../elements/TeaserBox';
import { Headline } from '../elements/Typography';
import { Container, FlexContainer } from './Containers';
import { phased } from '../../helpers/phases';

const StyledContainer = styled(FlexContainer)`
    justify-content: center;
`;

/**
 * PreFooter
 * ----------------------------------------------------------------------------
 * Der PreFooter erzeugt die Fläche vor dem Footer mit den darin enthaltenen
 * Teaser-Boxen.
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {object} props.data Result der GraphQL-Query (gatsby-image) (automatisch eingefügt)
 *
 * @example <PreFooter />
 */
const PreFooter = styled(({ data, className }) => (
    <Location>
        {({ location }) => {
            let boxes = [];
            const boxAnmelden = {
                title: 'Jetzt bewerben',
                link: '/online-bewerbung',
                image: data.teaserBewerben.childImageSharp,
            };
            const boxRueckblick = {
                title: 'Rückblick 2018',
                link: '/rueckblick',
                image: data.teaserRueckblick.childImageSharp,
            };
            const boxUeber = {
                title: 'Über den Sportjugend-Förderpreis',
                link: '/ueber-den-sjfp',
                image: data.teaserUeber.childImageSharp,
            };

            switch (location.pathname) {
                default:
                case '/':
                case '/online-bewerbung':
                    boxes = [boxUeber, boxRueckblick];
                    break;

                case '/ueber-den-sjfp':
                    boxes = phased([
                        [boxAnmelden, boxRueckblick],
                        [boxUeber, boxRueckblick],
                        [boxRueckblick],
                    ]);
                    break;

                case '/rueckblick':
                    boxes = phased([
                        [boxAnmelden, boxUeber],
                        [boxUeber, boxRueckblick],
                        [boxUeber],
                    ]);
                    break;
            }

            return (
                <>
                    {boxes && (
                        <StyledContainer className={`prefooter ${className}`}>
                            <Container size="m" padded>
                                <Headline level="h2">Weiterführende Informationen</Headline>

                                <StyledContainer grid>
                                    {boxes.map(box => <TeaserBox key={box.link} {...box} />)}
                                </StyledContainer>
                            </Container>
                        </StyledContainer>
                    )}
                </>
            );
        }}
    </Location>
))``;

PreFooter.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

PreFooter.defaultProps = {
    className: null,
};

export default props => (
    <StaticQuery
        query={graphql`
            query PreFooterQuery {
                teaserBewerben: file(relativePath: { eq: "teasers/teaser-bewerben.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 470, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
                teaserRueckblick: file(relativePath: { eq: "teasers/teaser-rueckblick.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 470, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
                teaserUeber: file(relativePath: { eq: "teasers/teaser-sjfp-logo.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 470, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        `}
        render={data => <PreFooter data={data} {...props} />}
    />
);
