import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { colors, position, remCalc, gapable, layerable, icons } from '../../helpers/stylehelpers';
import { Img } from './Images';
import TiltBox from './TiltBox';

/**
 * Teaser Box
 * ----------------------------------------------------------------------------
 * Eine verlinkte Box, die Inhalte mittels eines Bildes und eines darüber ge-
 * und blau hinterlegten Text-Blocks anteasert. Verwendet wird hierbei außerdem
 * eine TiltBox, um bei Hover einen entsprechenden Tilt-Effekt zu verursachen.
 *
 * @param {string} props.className Optional: Zusätzliche CSS-Klassen
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {object} props.image Das Bild der Box (aus einem graphql/gatsby-image)
 * @param {string} props.link Optional: Das Link-Ziel der Box
 * @param {string} props.title Optional: Der Text auf blauem Hintergrund
 *
 * @example
 *      <TeaserBox title="Jetzt suchen" link="https://www.google.de/" image={image} />
 */
const TeaserBox = styled(({ title, link, image, className }) => (
    <TiltBox link={link}>
        <Link to={link} className={`teaserbox ${className}`}>
            <Img className="teaserbox__image" title={title} alt={title} src={image.fluid} />
            <span className="teaserbox__title">{title}</span>
        </Link>
    </TiltBox>
))`
    background-color: ${colors.gray};
    display: block;
    max-width: ${remCalc(470)};
    min-width: ${remCalc(290)};
    overflow: hidden;

    ${layerable(1)};
    ${gapable()};

    .teaserbox__title {
        background-color: ${colors.primary};
        color: ${colors.white};
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        line-height: ${remCalc(30)};
        padding: ${remCalc(10)};
        ${position({ bottom: '0', left: '0', right: '0' })};

        &::after {
            content: ${icons.longArrow};
            display: block;
        }
    }
`;

TeaserBox.propTypes = {
    className: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    link: PropTypes.string,
    title: PropTypes.string,
};

TeaserBox.defaultProps = {
    className: null,
    gap: null,
    link: '#',
    title: 'Mehr erfahren',
};

export default TeaserBox;
