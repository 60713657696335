import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styledNormalize from 'styled-normalize';
import styled, { injectGlobal } from 'styled-components';
import { withPrefix } from 'gatsby';

import BrowseHappy from '../elements/BrowseHappy';
import Header from './Header';
import PreFooter from './PreFooter';
import Footer from './Footer';

import { fontFamily, colors, fs } from '../../helpers/stylehelpers';

import baseFontEot from '../../fonts/f1c68698-0462-43d2-94f5-eab3273cdf99.eot';
import baseFontWoff2 from '../../fonts/372b62d1-1266-4226-a5cc-22201cb1f770.woff2';
import baseFontWoff from '../../fonts/ec9fc863-ec1c-485e-8238-d37e5dc9b8eb.woff';
import baseFontTtf from '../../fonts/0e810e56-b457-4a4e-aa8a-341f6d3c9375.ttf';

import boldFontEot from '../../fonts/7d847ac4-ab65-4987-a435-e2fe10639423.eot';
import boldFontWoff2 from '../../fonts/910aea08-532e-437c-8552-b8e21e43a845.woff2';
import boldFontWoff from '../../fonts/cad8e11d-4e16-4cae-b102-c70567202fca.woff';
import boldFontTtf from '../../fonts/7f05091c-f0c1-46c9-8e19-b0d7144433a4.ttf';

/* eslint-disable no-unused-expressions */
injectGlobal`
    ${styledNormalize}

    @import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=abcdb00c-a05f-4a40-a305-2df084bab1f0");

    @font-face {
        font-family:"ITC Franklin Gothic LT";
        font-weight: normal;
        font-display: fallback;
        src:url(${`${baseFontEot}#iefix`});
        src:url(${`${baseFontEot}#iefix`}) format("eot"),
        url(${`${baseFontWoff2}`}) format("woff2"),
        url(${`${baseFontWoff}`}) format("woff"),
        url(${`${baseFontTtf}`}) format("truetype");
    }

    @font-face {
        font-family:"ITC Franklin Gothic LT";
        font-weight: bold;
        font-display: fallback;
        src:url(${`${boldFontEot}#iefix`});
        src:url(${`${boldFontEot}#iefix`}) format("eot"),
        url(${`${boldFontWoff2}`}) format("woff2"),
        url(${`${boldFontWoff}`}) format("woff"),
        url(${`${boldFontTtf}`}) format("truetype");
    }

    html {
        box-sizing: border-box;
    }

    *,
    *::after,
    *::before {
        box-sizing: inherit;
    }

    html,
    body {
        height: 100%;
        font-family: ${fontFamily};
    }

    body {
        overflow-x: hidden;
        color: ${colors.grayDark};
        ${fs('standard')}
    }

    p {
        line-height: 1.4em;
        margin: 0 0 0.25em 0;
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: 1;
        margin: 0 0 0.25em;
    }

    a {
        text-decoration: none;
        color: ${colors.black};
    }

`;
/* eslint-enable no-unused-expressions */

/**
 * Das Basis-Layout
 *
 * @param {node} props.children Inhalte der Seite
 * @param {string} props.title Der Titel der Seite
 * @param {string} props.description Die Meta-Description
 *
 * @example
 *      <BaseLayout title="Downloads" description="Lorem Ipsum Meta Description">
 *          <Stage>...</Stage>
 *      </BaseLayout>
 */

const Wrapper = styled.div`
    overflow-x: hidden;
`;

const socialTitle = 'Lotto Sportjugend-Förderpreis belohnt vorbildliche Jugendarbeit';
const socialDescription =
    'BW-Sportvereine aufgepasst: Den Gewinnern des Lotto Sportjugend-Förderpreises winken 100.000 Euro. Wer mitmachen möchte, stellt sein Projekt vor und schickt die Bewerbung bis Ende 2018 an Toto-Lotto.';
const url = 'https://www.sportjugendfoerderpreis.de';

const BaseLayout = ({ children, title, description, disablePrefooter }) => (
    <Wrapper>
        <Helmet>
            <title>
                {`${title !== '' ? `${title} / ` : ''}`}LOTTO AWARD Sportjugend-Förderpreis
            </title>
            <meta name="description" content={description} />
            <link rel="shortcut icon" href={withPrefix('/favicons/icon-48x48.png')} />
            <html lang="de" />

            {/* OpenGraph */}

            <meta property="og:title" content={socialTitle} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={socialDescription} />
            <meta property="og:image" content={`${url}/logo-opengraph.png`} />

            {/* Twitter Card */}

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={socialTitle} />
            <meta name="twitter:description" content={socialDescription} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:image" content={`${url}/logo-opengraph-square.png`} />

            <script
                type="application/javascript"
                src="//app.usercentrics.eu/latest/main.js"
                id="JQtMEpxX"
            />
        </Helmet>
        <BrowseHappy />
        <Header />
        {children}
        {!disablePrefooter && <PreFooter />}
        <Footer forceWhiteBefore={disablePrefooter} />
    </Wrapper>
);

BaseLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    disablePrefooter: PropTypes.bool,
};

BaseLayout.defaultProps = {
    disablePrefooter: false,
};

export default BaseLayout;
