import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
    bgColorable,
    colors,
    containers,
    gapable,
    gaps,
    layerable,
    mq,
    sizable,
    textAlignable,
    gridPosable,
    remCalc,
} from '../../helpers/stylehelpers';

/**
 * Container
 * ----------------------------------------------------------------------------
 * Der Standard-Container bildet einen Wrapper für jeglichen Content.
 *
 * @param {bool} props.cols Optional: Anzahl der Spalten
 * @param {string} props.bgColor Optional: die Hintergrundfarbe
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {bool} props.grid Optional: Grid aktivieren
 * @param {number} props.gridColStart Optional: die Startposition im Grid
 * @param {number} props.gridColEnd Optional: die Endposition im Grid
 * @param {bool} props.inline Optional: Container auf inline-block stellen
 * @param {number} props.layer Optional: die Ebene/Größe des Schattens
 * @param {bool} props.padded Optional: Container mit Padding versehen
 * @param {string} props.size Optional: die Größe des Containers ('s', 'm', 'l', 'fullWidth')
 * @param {string} props.textAlign Optional: Ausrichtung des Texts
 *
 * @example <Container padded={true} size="m">...</Container>
 */
export const Container = styled(({ className, children }) => (
    <div className={`container ${className}`}>{children}</div>
))`
    display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
    margin-left: auto;
    margin-right: auto;
    padding: ${({ padded }) => (padded ? `${gaps.xxl} ${gaps.l}` : 0)};
    width: 100%;
    ${bgColorable()};
    ${gapable()};
    ${gridPosable()};
    ${layerable(0)};
    ${sizable()};
    ${textAlignable()};

    ${mq.large`
        padding: ${({ padded }) => (padded ? gaps.xxl : 0)};
    `};

    ${({ grid, cols }) =>
        grid &&
        cols &&
        css`
            ${mq.medium`

                > * {
                    margin-top: 1.5em;
                }
   
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: ${'1fr '.repeat(cols - 1)};
                    grid-column-gap: 1em;
                    grid-row-gap: 1em;     

                    & > * {
                        margin-top: 0;
                    }
                }
            `};

            ${mq.large`
                grid-template-columns: ${'1fr '.repeat(cols)};
            `};
        `};
`;

Container.propTypes = {
    bgColor: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    grid: PropTypes.bool,
    gridColStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gridColEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inline: PropTypes.bool,
    layer: PropTypes.number,
    padded: PropTypes.bool,
    size: PropTypes.string,
    textAlign: PropTypes.string,
};

Container.defaultProps = {
    bgColor: null,
    gap: null,
    grid: null,
    gridColStart: null,
    gridColEnd: null,
    inline: null,
    layer: null,
    padded: null,
    size: null,
    textAlign: null,
};

/**
 * FlexContainer
 * ----------------------------------------------------------------------------
 * FlexContainer erweitern den Standard-Container um die Flex-
 * Eigenschaften und ermöglichen zusätzlich ein simples Grid-
 * System:
 *
 * @param {bool} props.grid Optional: Simples Auto-Grid aktivieren
 * @param {string} props.justifyContent Optional: Inhalt ab large ausrichten
 *
 * Außerdem werden vom Container folgende Props vererbt:
 * @param {string} props.bgColor Optional: die Hintergrundfarbe
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.gridColStart Optional: die Startposition im Grid
 * @param {number} props.gridColEnd Optional: die Endposition im Grid
 * @param {bool} props.inline Optional: Container auf inline-flex stellen
 * @param {number} props.layer Optional: die Ebene/Größe des Schattens
 * @param {bool} props.padded Optional: Container mit Padding versehen
 * @param {string} props.size Optional: die Größe des Containers ('s', 'm', 'l', 'fullWidth')
 * @param {string} props.textAlign Optional: Ausrichtung des Texts
 *
 * @example <FlexContainer grid={true}>...</FlexContainer>
 */
export const FlexContainer = styled(({ className, children, ...props }) => (
    <Container className={`container--flex ${className}`} {...props}>
        {children}
    </Container>
))`
    display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
    flex-wrap: wrap;
    justify-content: space-around;
    width: auto;

    ${mq.large`
        justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    `};

    ${({ grid }) =>
        grid &&
        css`
            ${({ gap }) =>
                gap
                    ? `margin: -0.5em; margin-bottom: calc(${gaps[gap]} - 0.5em);`
                    : 'margin: -0.5em'};

            ${({ size }) =>
                containers[size]
                    ? `margin-left: auto; margin-right: auto; max-width: calc(${
                          containers[size]
                      } + 1em)`
                    : ''};

            & > * {
                flex: 1 0 8em;
                margin: 0.5em;
            }
        `};
`;

FlexContainer.propTypes = {
    ...Container.propTypes,
    span: PropTypes.string,
    justifyContent: PropTypes.string,
};

FlexContainer.defaultProps = {
    ...Container.defaultProps,
    span: null,
    justifyContent: null,
};

/**
 * Section
 * ----------------------------------------------------------------------------
 * Sektionen bilden die abwechselnd blauen und weißen Content-Bereiche der
 * Seite. Abhängig von der Farbigkeit der letzten Sektion wird auch der
 * PreFooter sowie der Footer eingefärbt.
 *
 * Über das `container` Attribut kann außerdem ein Container mit der übergebenen
 * Größe integriert werden:
 *
 * @param {string} props.bgColor Optional: die Hintergrundfarbe
 * @param {string} props.container Optional: ('s', 'm', 'l', 'fullWidth')
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {node} props.children? Inhalte der Section
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.textalign Optional: Ausrichtung des Texts
 *
 * @example <section container="m">...</section>
 */
export const Section = styled(({ className, container, children }) => (
    <section className={`section ${className}`}>
        {container ? (
            <Container className="section__container" size={container}>
                {children}
            </Container>
        ) : (
            children
        )}
    </section>
))`
    ${bgColorable('lightBlue')};
    ${gapable({
        small: 'l',
        medium: 'xl',
        large: 'xxl',
        xlarge: 'xxxl',
    })};
    ${textAlignable()};

    ${({ disableVerticalPadding }) =>
        disableVerticalPadding && 'padding-top: 0 !important; padding-bottom: 0 !important;'}

    // Padding für blaue Sektionen
    padding: ${remCalc(25, 15, 35)};

    ${mq.medium`
        padding: ${remCalc(30, 15, 40)};
    `};

    ${mq.large`
        padding: ${remCalc(40, 15, 55)};
    `};

    // Normale, weiße Sektionen mit weniger Padding
    &:nth-of-type(2n + 1) {
        ${bgColorable('transparent')};

        padding: ${remCalc(15, 15, 15)};

        ${mq.medium`
            padding: ${remCalc(20, 15, 20)};
        `};

        ${mq.large`
            padding: ${remCalc(30, 15, 40)};
        `};

        &:last-of-type ~ .prefooter,
        &:last-of-type ~ .footer > .footer__logowrapper::before {
                background-color: ${colors.lightBlue};
        }
        
        &:last-of-type ~ .prefooter,
        &:last-of-type ~ .footer > .footer__logowrapper {
                border-top-color: ${colors.lightBlue};
        }

    }

    > p:last-child {
        margin-bottom: 0;
    }
`;

Section.propTypes = {
    bgColor: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    container: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textalign: PropTypes.string,
    disableVerticalPadding: PropTypes.bool,
};

Section.defaultProps = {
    bgColor: null,
    className: '',
    container: null,
    gap: null,
    textalign: null,
    disableVerticalPadding: false,
};
