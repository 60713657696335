import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImg from 'gatsby-image';

import { shapeGatsbyImageSharpFluidWithWebpTracedSVG } from '../../helpers/shapes';
import { gapable } from '../../helpers/stylehelpers';

/**
 * Standard Image
 * ----------------------------------------------------------------------------
 * Als Standard Bild wird ein gatsby-image verwendet.
 *
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.title Optional: Der Titel des Bildes
 * @param {string} props.alt Optional: Der Alternativtext des Bildes
 * @param {object} props.src Das Bild als String oder Gatsby-Image/Fluid
 *
 * @example
 *      <Img
 *          title="Philosophie des Sportjugendförderpreis"
 *          alt="Hände übereinander"
 *          gap="l"
 *          src={data.contentAbout.childImageSharp.fluid} />
 */
export const Img = styled(
    ({ title, alt, src, ...props }) =>
        typeof src === 'string' ? (
            <img src={src} alt={alt} title={title} {...props} />
        ) : (
            <GatsbyImg alt={alt} title={title} fluid={src} {...props} />
        )
)`
    ${gapable()};
    max-width: 100%;
`;

Img.propTypes = {
    alt: PropTypes.string,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    src: PropTypes.oneOfType([PropTypes.string, shapeGatsbyImageSharpFluidWithWebpTracedSVG])
        .isRequired,
    title: PropTypes.string,
};

Img.defaultProps = {
    alt: null,
    gap: null,
    title: null,
};

export default Img;
