import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    color: #ffffff;
    background-color: #cc4b37;
    font-family: Arial, sans-serif;
    display: block;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 15px;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;

    > a,
    > a:hover,
    > a:active {
        color: #ffffff;
        text-decoration: underline;
    }
`;

class BrowseHappy extends React.Component {
    /**
     * Überprüft, ob übergebenes Prop in Style-Tag ist
     * @param {string} prop
     */
    static supports(prop) {
        const div = document.createElement('div');
        const vendors = 'Khtml Ms O Moz Webkit'.split(' ');
        let len = vendors.length;
        let property = prop;

        if (prop in div.style) return true;

        property = property.replace(/^[a-z]/, val => val.toUpperCase());

        while (len > 0) {
            len -= 1;
            if (vendors[len] + property in div.style) {
                return true;
            }
        }
        return false;
    }

    state = {
        isOldBrowser: false,
    };

    /**
     * Did Mount
     */
    componentDidMount() {
        this.setState({
            isOldBrowser: !BrowseHappy.supports('borderImage'),
        });
    }

    /**
     * Render
     */
    render() {
        const { isOldBrowser } = this.state;
        const hint = isOldBrowser && (
            <Wrapper>
                Sie verwenden einen stark veralteten Browser. Bitte{' '}
                <a href="http://outdatedbrowser.com/de" target="_blank" rel="noopener noreferrer">
                    aktualisieren Sie ihren Browser
                </a>{' '}
                für eine optimale Nutzererfahrung.
            </Wrapper>
        );

        return <>{hint}</>;
    }
}

export default BrowseHappy;
