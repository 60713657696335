/* eslint-disable import/prefer-default-export */
/**
 * Wrapper verschiedener
 */
const win =
    typeof window !== 'undefined'
        ? window
        : { atob: a => Buffer.from(a, 'base64').toString('binary') };

export default win;
