import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { Link } from 'gatsby';

import { colors, position, remCalc, gapable, mq } from '../../helpers/stylehelpers';

/**
 * Tilt Box
 * ----------------------------------------------------------------------------
 * Eine Box, die einen Tilt-Hover-Effekt ermöglicht, bei dem die Box je nach
 * Position des Mauszeigers innerhalb der Box in die entsprechende Richtung
 * im 3D Raum "eingedrückt" wird.
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {node} props.children Inhalte der Box
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.link Optional: Link-Ziel der Box
 *
 * @example <TiltBox link="https://www.google.de">...</TiltBox>
 */
const TiltBox = styled(({ className, link, children }) => (
    <div className={`tiltbox ${className}`}>
        <Link to={link} className="tiltbox__trigger" aria-hidden="true" />
        <Link to={link} className="tiltbox__trigger" aria-hidden="true" />
        <Link to={link} className="tiltbox__trigger" aria-hidden="true" />
        <Link to={link} className="tiltbox__trigger" aria-hidden="true" />
        <div className="tiltbox__content">{children}</div>
    </div>
))`
    display: block;
    max-width: ${remCalc(470)};
    min-width: ${remCalc(290)};
    perspective: 1500px;
    position: relative;
    width: 100%;

    ${mq.mediumDown`
        flex: 0 0 auto !important;
    `};

    ${gapable()};

    .tiltbox__trigger {
        cursor: pointer;
        color: transparent;
        display: block;
        height: 50%;
        opacity: 0;
        position: absolute;
        width: 50%;
        z-index: 1;

        &:hover,
        &:focus {
            transform: translateZ(48px);

            ~ .tiltbox__content {
                transform: translateZ(24px);
            }
        }

        &:active,
        &:focus {
            outline: none;
        }

        // Top Left
        &:nth-child(1) {
            ${position({ top: '0', left: '0' })};

            &:hover,
            &:focus {
                ~ .tiltbox__content {
                    box-shadow: -5px -5px 15px ${rgba(colors.black, 0.2)};
                    transform-origin: right bottom;
                    transform: rotateX(5deg) rotateY(-5deg) translateZ(0);
                }
            }
        }

        // Top Right
        &:nth-child(2) {
            ${position({ top: '0', right: '0' })};

            &:hover,
            &:focus {
                ~ .tiltbox__content {
                    box-shadow: 5px -5px 15px ${rgba(colors.black, 0.2)};
                    transform-origin: left bottom;
                    transform: rotateX(5deg) rotateY(5deg) translateZ(0);
                }
            }
        }

        // Bottom Right
        &:nth-child(3) {
            ${position({ bottom: '0', right: '0' })};

            &:hover,
            &:focus {
                ~ .tiltbox__content {
                    box-shadow: 5px 5px 15px ${rgba(colors.black, 0.2)};
                    transform-origin: left top;
                    transform: rotateX(-5deg) rotateY(5deg) translateZ(0);
                }
            }
        }

        // Bottom Left
        &:nth-child(4) {
            ${position({ bottom: '0', left: '0' })};

            &:hover,
            &:focus {
                ~ .tiltbox__content {
                    box-shadow: -5px 5px 15px ${rgba(colors.black, 0.2)};
                    transform-origin: right top;
                    transform: rotateX(-5deg) rotateY(-5deg) translateZ(0);
                }
            }
        }
    }

    .tiltbox__content {
        position: relative;
        transition: all 300ms ease-out;
        z-index: 0;
    }
`;

TiltBox.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.string,
};

TiltBox.defaultProps = {
    className: null,
    gap: null,
    link: '#',
};

export default TiltBox;
