/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

/**
 * Shape für GatsbyImageSharpFluid_withWebp_tracedSVG
 */
export const shapeGatsbyImageSharpFluidWithWebpTracedSVG = PropTypes.shape({
    tracedSVG: PropTypes.string.isRequired,
    aspectRatio: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcWebp: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
    sizes: PropTypes.string.isRequired,
});

/**
 * Shape für Bilder
 */
export const shapeImage = PropTypes.shape({
    childImageSharp: PropTypes.shape({
        fluid: PropTypes.object,
    }),
});
