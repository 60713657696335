import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, remCalc, mq, position, pseudoStateClasses } from '../../helpers/stylehelpers';
import { Link } from '../elements/ButtonsAndLinks';

import footerLogo from '../../images/logos/lotto-bw.svg';

/**
 * Footer Komponente
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 *
 * @example <Footer />
 */
const Footer = styled(({ className }) => (
    <div className={`footer ${className}`}>
        <div className="footer__logowrapper">
            <img className="footer__logo" alt="LOTTO BW Logo" src={footerLogo} />
        </div>
        <div className="footer__meta">
            <div className="footer__copy">Staatliche Toto-Lotto GmbH Baden-Württemberg</div>
            <nav className="footer__nav">
                <Link className="footer__link" to="/kontakt" type="plain">
                    Kontakt
                </Link>
                <Link className="footer__link" to="/impressum" type="plain">
                    Impressum
                </Link>
                <Link className="footer__link" to="/datenschutz" type="plain">
                    Datenschutz
                </Link>
                <Link className="footer__link" to="/teilnahmebedingungen" type="plain">
                    Teilnahmebedingungen
                </Link>
            </nav>
        </div>
    </div>
))`
    position: relative;
    overflow: hidden;

    .footer__logowrapper {
        background-color: ${colors.yellow};
        justify-content: center;
        border-top: 1px solid ${colors.white};

        &:before {
            ${position({ top: '0', left: '-15%' })};
            display: block;
            content: '';
            border-bottom-left-radius: 50% 40%;
            border-bottom-right-radius: 50% 40%;
            background-color: ${({ forceWhiteBefore }) =>
                forceWhiteBefore ? `${colors.white} !important` : colors.white};
            height: ${remCalc(300)};
            position: absolute;
            top: 0;
            transform: translateY(-80%);
            left: -15%;
            width: 130%;

            ${mq.medium`
                left: -25%;
                width: 150%;
                height: ${remCalc(350)};
                border-bottom-left-radius: 60% 95%;
                border-bottom-right-radius: 60% 95%;
            `};

            ${mq.xlarge`
                left: -30%;
                width: 160%;
                height: ${remCalc(500)};
                border-bottom-left-radius: 55% 90%;
                border-bottom-right-radius: 55% 90%;
            `};

            ${mq.xxlarge`
                left: -30%;
                width: 160%;
                height: ${remCalc(650)};
                border-bottom-left-radius: 50% 90%;
                border-bottom-right-radius: 50% 90%;
            `};
        }
    }

    .footer__logo {
        display: block;
        max-width: ${remCalc(150)};
        padding: ${remCalc(80, 0, 20)};
        margin: 0 auto;

        ${mq.medium`padding: ${remCalc(90, 0, 20)}`};
        ${mq.xlarge`padding: ${remCalc(125, 0, 20)}`};
        ${mq.xxlarge`
            max-width: ${remCalc(180)};
            padding: ${remCalc(160, 0, 25)};
        `};
    }

    .footer__meta {
        padding: ${remCalc(15, 15)};
        max-width: ${remCalc(1200)};
        margin: 0 auto;
        text-align: center;

        ${mq.large`
            display: flex;
            texta-align: left;
            justify-content: space-between;
            padding: ${remCalc(15, 15)};
        `};
        ${mq.large`font-size: ${remCalc(18)}`};
    }

    .footer__copy {
        margin: ${remCalc(0, 0, 10)};
        padding: ${remCalc(0, 0, 10, 0)};
        ${mq.mediumDown`
            border-bottom: 1px solid ${colors.gray};
        `};

        ${mq.large`
            margin: ${remCalc(0, 15, 0, 0)};
            padding: 0;
        `};
    }

    .footer__link {
        ${mq.smallOnly`
            display: block;
            margin: ${remCalc(0, 0, 15)};
            &:last-child {
                margin-bottom: 0;
            }
        `};

        ${mq.medium`
            margin: ${remCalc(0, 10, 0, 0)};
            &:last-child {
                margin-right: 0;
            }
        `};

        ${mq.large`
            margin: ${remCalc(0, 15, 0, 0)};
            &:last-child {
                margin-right: 0;
            }
        `};

        ${pseudoStateClasses`
            text-decoration: underline;
        `};
    }
`;

Footer.propTypes = {
    className: PropTypes.string,
    forceWhiteBefore: PropTypes.bool,
};

Footer.defaultProps = {
    className: null,
    forceWhiteBefore: false,
};

export default Footer;
