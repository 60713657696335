/**
 * Die Phasen der Phasen-Umschaltung
 */
const phases = [
    {
        // Phase 1 (Bewerbung) geht bis 18.01.2021 um 23:59:59 Uhr
        phase: 1,
        start: new Date(1970, 1, 1, 0, 0, 1),
        end: new Date(2021, 0, 17, 23, 59, 59),
    },
    {
        // Phase 2 (Bewerbung nicht mehr möglich, Jury ist an der Bewertung)
        // siehe "Preise" Seite -> Zitat "Die Gewinner werden im März 2019 bekanntgegeben."
        // vom 18.01.2021 bis 28.04.2021
        phase: 2,
        start: new Date(2021, 0, 18, 0, 0, 0),
        end: new Date(2021, 3, 28, 23, 59, 59),
    },
    {
        // Phase 3 (Gewinner sind ermittelt) ab 29.04.2021 (exaktes Datum tba.)
        phase: 3,
        start: new Date(2021, 3, 29, 0, 0, 0),
        end: new Date(2032, 12, 31, 23, 59, 59),
    },
];

/**
 * Ermittelt die aktuelle Phase anhand des übergebenen Datums (default = now)
 * @param {Date} currentDate
 */
export const currentPhase = (currentDate = new Date()) =>
    phases
        .filter(
            phase =>
                phase.start.getTime() <= currentDate.getTime() &&
                currentDate.getTime() < phase.end.getTime()
        )
        .pop();

/**
 * Liefert das Element eines Arrays der aktuellen Phase
 * @param {Array} items
 *
 * @example phased(['Headline Phase 1', 'Headline Phase 2', 'Headline Phase 3'])
 */
export const phased = items => items[currentPhase().phase - 1];
