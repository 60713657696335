/* eslint-disable import/prefer-default-export */
import { keyframes } from 'styled-components';

/**
 * Shake Animation#
 * @type {Keyframes}
 */
export const shake = keyframes`
    0% {
      transform: translate(3px, 0);
    }
    50% {
      transform: translate(-3px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
`;
