import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import {
    colors,
    fs,
    gapable,
    textAlignable,
    textColorable,
    gridPosable,
} from '../../helpers/stylehelpers';

/**
 * Headlines
 * ----------------------------------------------------------------------------
 * Dieses Element bildet alle Überschriften ab.
 *
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.level Die Ebene der Überschrift ('h1', 'h2', 'h3', 'h4', 'h5', 'h6')
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'l'
 * @param {string} props.textAlign Optional: Ausrichtung des Textes
 * @param {string} props.textColor Optional: Farbe des Textes
 *
 * @example <Headline level="h2" gap="m">Lorem ipsum Headline</Headline>
 */
export const Headline = styled(({ level, children, className }) => {
    const HL = level;
    return <HL className={className}>{children}</HL>;
})`
    font-weight: bold;
    line-height: 1.4em;
    margin-top: 0;

    ${({ level }) => fs(level)};
    ${gapable('m')};
    ${textAlignable()};
    ${textColorable()};
    ${gridPosable()};

    ${({ level, textAlign }) =>
        level === 'h2' &&
        css`
            color: ${colors.primary};
            ${!textAlign && 'text-align: center;'};
        `};
`;

Headline.propTypes = {
    className: PropTypes.string,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gridColStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gridColEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textAlign: PropTypes.string,
    textColor: PropTypes.string,
};

Headline.defaultProps = {
    className: null,
    gap: null,
    textAlign: null,
    textColor: 'black',
    gridColEnd: null,
    gridColStart: null,
};

/**
 * Paragraphs
 * ----------------------------------------------------------------------------
 * Absätze.
 *
 * @param {bool} props.bold Optional: Zeichnet den Text als "fett" aus
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'm'
 * @param {string} props.textAlign Optional: Ausrichtung des Textes
 *
 * @example <P bold={true} gap="l">...</P>
 */
export const P = styled.p`
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    ${gapable('m')};
    ${textAlignable()};
    line-height: 1.4em;
`;

P.propTypes = {
    bold: PropTypes.bool,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textAlign: PropTypes.string,
};

P.defaultProps = {
    bold: false,
    gap: null,
    textAlign: null,
};
