import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';

import { ButtonLink } from './ButtonsAndLinks';

import { colors, remCalc, mq } from '../../helpers/stylehelpers';

/**
 * PropTypes
 * @type {Object}
 */
const propTypes = {
    headline: PropTypes.shape({
        text: PropTypes.string,
        level: PropTypes.string,
        smaller: PropTypes.bool,
    }).isRequired,
    subline: PropTypes.string,
    copy: PropTypes.node,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            to: PropTypes.string,
            target: PropTypes.string,
        })
    ),
};

/**
 * Default Props
 * @type {Object}
 */
const defaultProps = {
    subline: null,
    copy: null,
    links: [],
};

/**
 * Wrapper um das StageIntro
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    display: block;
    text-align: center;
    color: ${colors.white};
    padding: ${remCalc(50, 15, 40)};
    max-width: ${remCalc(500)};

    ${mq.medium`
        padding: ${remCalc(40, 15, 40)};
    `};

    ${mq.large`
        max-width: ${remCalc(600)};
        padding: ${remCalc(30, 15, 40)};
    `};

    ${mq.xlarge`
        max-width: ${remCalc(700)};
        padding: ${remCalc(40, 15, 50)};
    `};

    ${mq.xxlarge`
        padding: ${remCalc(60, 15, 70)};
    `};
`;

/**
 * Headline des StageIntros
 * @type {StyledComponent}
 */
const Headline = styled.h1`
    color: ${colors.white};
    font-size: ${({ smaller }) => (smaller ? `${remCalc(35)}` : `${remCalc(40)}`)};
    margin: 0 0 0.15em;

    ${mq.large`
        font-size: ${({ smaller }) => (smaller ? `${remCalc(40)}` : `${remCalc(50)}`)}
    `};

    ${mq.xlarge`
        font-size: ${({ smaller }) => (smaller ? `${remCalc(50)}` : `${remCalc(60)}`)};
    `};

    ${mq.xxlarge`
        font-size: ${({ smaller }) => (smaller ? `${remCalc(70)}` : `${remCalc(80)}`)}
    `};
`;

/**
 * Subline (optional)
 * @type {StyledComponent}
 */
const Subline = styled.h2`
    font-weight: normal;
    font-size: ${remCalc(20)};

    ${mq.large`
        font-size: ${remCalc(25)};
    `};

    ${mq.xlarge`
        font-size: ${remCalc(30)};
    `};

    ${mq.xxlarge`
        font-size: ${remCalc(35)};
    `};
`;
/**
 * Subline (optional)
 * @type {StyledComponent}
 */
const SublineH3 = styled.h3`
    color: ${colors.yellow};
    font-weight: bold;
    font-size: ${remCalc(16)};

    ${mq.large`
        font-size: ${remCalc(20)};
    `};

    ${mq.xlarge`
        font-size: ${remCalc(24)};
    `};

    ${mq.xxlarge`
        font-size: ${remCalc(30)};
    `};
`;

/**
 * Fließtext
 * @type {StyledComponent}
 */
const Copy = styled.p`
    display: block;
    border-top: 1px solid ${rgba(colors.white, 0.5)};
    font-size: ${remCalc(18)};
    margin: 0.6em 0;
    padding: 0.4em 1em 0;

    &:last-child {
        margin-bottom: 0;
    }

    ${mq.large`
        font-size: ${remCalc(20)};
    `};

    ${mq.xlarge`
        font-size: ${remCalc(22)};
    `};

    ${mq.xxlarge`
        font-size: ${remCalc(25)};
    `};
`;

/**
 * Wrapper um etwaige Buttons
 * @type {StyledComponent}
 */
const Buttongroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mq.medium`
        flex-direction: row;
    `};

    > * {
        width: 100%;
        margin: ${remCalc(0, 0, 15)};
        ${mq.medium`
            width: auto;
            margin: ${remCalc(0, 15, 0, 0)};
            &:last-child {
                margin-right: 0;
            }
        `};
    }
`;

/**
 * Stage Komponente (Layout)
 * @param {*} props
 */
const Stage = ({ headline, subline, copy, links }) => {
    const HeadlineElement = headline.level ? Headline.withComponent(headline.level) : Headline;
    const linkButtons =
        links &&
        links.length > 0 &&
        links.map(link => (
            <ButtonLink key={link.to} color={colors.white} to={link.to} target={link.target}>
                {link.text}
            </ButtonLink>
        ));
    return (
        <Wrapper>
            <SublineH3>
                Der Lotto Sportjugend-Förderpreis wird 2022 nicht ausgeschrieben. Eine Neuauflage
                unseres Vereinswettbewerbs ist für 2024 geplant.
            </SublineH3>
            <HeadlineElement smaller={headline.smaller}>{headline.text}</HeadlineElement>
            {subline !== null && <Subline>{subline}</Subline>}
            {copy !== null && <Copy>{copy}</Copy>}
            {linkButtons && <Buttongroup>{linkButtons}</Buttongroup>}
        </Wrapper>
    );
};

Stage.propTypes = propTypes;
Stage.defaultProps = defaultProps;

export default Stage;
